body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}

.container {
    max-width: 92%;
}

button {
    outline: none !important;
}

header {
    position: fixed;
    left: 0px;
    width: 100%;
    z-index: 1000;
    height: 75px;
    box-shadow: -1px 3px 4px #d8d3d3;
}

#mob-app-nav {
    display: none;
}

.main-pg {
    overflow: hidden;
}

.redss {
    border: 2px solid red !important;
}

input.valid-alert.form-control.bdinput {
    border: 2px solid red !important;
}

.valid-alert::placeholder {
    color: red !important;
}

.select .valid-alert {
    border: 2px solid red !important;
}

.dd-form,
.cd-form {
    padding: 20px 20px 0 20px;
    background: #fff;
    border-radius: 0;
    display: none;
    border-radius: 6px;
    box-shadow: 0px 0px 7px rgb(221 221 221 / 52%);
}


/* header nav */

.navigation {
    height: 75px;
    background: #fff;
}

.navigation .nav-container nav ul,
.navigation .nav-container nav ul li,
.navigation .nav-container nav ul li a {
    min-height: 75px;
}

.navigation .nav-container nav ul li ul,
.navigation .nav-container nav ul li ul li,
.navigation .nav-container nav ul li ul li a {
    min-height: unset;
}

.brand {
    position: absolute;
    padding-left: 20px;
    float: left;
    line-height: 70px;
    text-transform: uppercase;
    font-size: 1.4em;
}

.brand a,
.brand a:visited {
    color: #fff;
    text-decoration: none;
}

.nav-container {
    max-width: 1295px;
    margin: 0 auto;
}

nav {
    float: right;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    float: left;
    position: relative;
}

nav ul li a,
nav ul li a:visited {
    display: block;
    padding: 0 20px;
    line-height: 70px;
    color: #000;
    text-decoration: none;
}

nav ul li a:hover,
nav ul li a:visited:hover {
    background: #0393d9;
    color: #fff;
    text-decoration: none;
}

nav ul li a:not(:only-child):after,
nav ul li a:visited:not(:only-child):after {
    padding-left: 4px;
    content: ' ▾';
}

nav ul li ul li {
    min-width: 190px;
    font-size: 14px;
}

nav ul li ul li a {
    padding: 15px;
    line-height: 20px;
}

.nav-dropdown {
    position: absolute;
    display: none;
    z-index: 1;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    width: 561px;
    background: rgb(255, 255, 255);
}

@media only screen and (max-width: 1280px) {
    .navigation nav {
        padding-right: 30px;
    }
}

@media only screen and (max-width: 940px) {
    .brand {
        width: 190px;
    }
}

@media only screen and (max-width: 860px) {
    .brand {
        width: 200px;
    }
}

@media only screen and (max-width: 799px) {
    .brand {
        width: 200px;
    }
}


/* Mobile navigation */

.nav-mobile {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    height: 70px;
    width: 70px;
}

@media only screen and (max-width: 799px) {
    ul.nav-list {
        background: #fff;
        width: 105%;
    }
    .navigation small {
        padding: 0px 5px;
    }
    .navigation button {
        margin: 0px !important;
    }
    .navigation .nav-container nav ul,
    .navigation .nav-container nav ul li,
    .navigation .nav-container nav ul li a {
        min-height: unset;
        padding: 10px 5px;
        padding-left: 10px;
    }
    .call_mob {
        display: block !important;
    }
    .nav-mobile {
        display: block;
    }
    nav {
        padding: 70px 0 15px;
        width: 100%;
        position: absolute;
        z-index: 222;
    }
    nav ul {
        display: none;
    }
    nav ul li {
        float: none;
        background: #fff;
    }
    nav ul li a {
        padding: 15px;
        line-height: 20px;
    }
    nav ul li ul li a {
        padding-left: 30px;
    }
    .nav-dropdown {
        position: static;
    }
    /* mobile app model design */
    /* .brand {
    position: relative;
    float: left;
    line-height: 70px;
    text-transform: uppercase;
    font-size: 1.4em;
    max-width: 250px;
    margin: 0px 15%;
} */
    .call_li {
        padding: 16px 15px;
        border-left: 1px solid #0000000d;
    }
    .call_mob h4 {
        font-size: 13pt;
    }
    .nav-mobile {
        display: block;
    }
    footer img,
    footer .navbar {
        display: none;
    }
    .col-xs-3.col-sm-3 {
        width: 25%;
    }
    /* #mob-app-nav {
    display: block;
    padding-top: 20px;
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #fff;
    z-index: 999;
    max-width: 799px
} */
    footer {
        margin-top: 0px;
        position: relative;
    }
    .cls-1 {
        fill: #898989;
        fill-rule: evenodd;
    }
    .cls-2 {
        fill: #fff;
        fill-rule: evenodd;
    }
    .mb-active {
        background: #fbb022;
        padding: 7px;
        border-radius: 50px;
    }
    .mb-inactive {
        background: #efefef;
        padding: 7px;
        border-radius: 50px;
    }
    #mob-app-nav .mb-lnk-act h6 {
        font-size: 11pt;
        margin: 9px;
        color: #fbb022;
    }
    #mob-app-nav .mb-lnk-inact h6 {
        font-size: 11pt;
        margin: 9px;
        color: #898989;
    }
    .mb-col-active {
        border-bottom: 3px solid #fbb022;
    }
    #mob-app-nav a {
        text-decoration: none;
    }
    #mob-app-nav a:hover {
        text-decoration: none;
    }
    footer {
        padding: 29px 0px 30px !important;
        margin-top: 15px !important;
    }
}

@media screen and (min-width: 800px) {
    .nav-list {
        display: block !important;
    }
}

@media screen and (max-width: 399px) {
    .brand {
        max-width: 50%;
    }
}

#nav-toggle {
    position: absolute;
    left: 0px;
    top: 22px;
    cursor: pointer;
    padding: 10px 35px 16px 0px;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: #000;
    position: absolute;
    display: block;
    content: '';
    transition: all 300ms ease-in-out;
}

#nav-toggle span:before {
    top: -10px;
}

#nav-toggle span:after {
    bottom: -10px;
}

#nav-toggle.active span {
    background-color: transparent;
}

#nav-toggle.active span:before,
#nav-toggle.active span:after {
    top: 0;
}

#nav-toggle.active span:before {
    transform: rotate(45deg);
}

#nav-toggle.active span:after {
    transform: rotate(-45deg);
}

article {
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px;
}

.navigation button {
    padding: 6px 15px;
    margin: 15px 19px 15px 5px;
    color: #fff;
    border: none;
    background: #0393d9;
    border-radius: 20px;
    border: 1px solid;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.navigation button:hover {
    background: #fff;
    color: #0393d9;
    border: 1px solid;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.navigation small {
    color: #0393d9;
}


/* header nav css ends here */


/* cover sec css starts here */

#cover {
    padding-top: 75px;
    overflow: hidden;
}

.cover {
    top: 0px;
    position: relative;
}

.slider {
    max-width: -webkit-fill-available;
    height: 563px;
    position: relative;
}

.slides,
.slides2 {
    display: none;
}

.slide1,
.slide2,
.slide3,
.slide4,
.slide5 {
    position: absolute;
    width: 100%;
    height: 100%;
}

.slide1 {
    background-size: cover;
}

.slide2 {
    background-size: cover;
}

.slide3 {
    background-size: cover;
}

.cvr-right {
    background: url(../images/banner-ovarlay-img.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 124px 50px;
    height: 563px;
}

.cvr-right.animated.bounceInRight {
    width: 99.9%;
}

.cvr-right-inner h1 {
    color: #fff;
    font-weight: 700;
    font-size: 28pt;
    line-height: 33px;
}

.cvr-right-inner p {
    color: #fff;
}

.cvr-right-inner button {
    padding: 7px 28px;
    border-radius: 20px;
    border: 1px solid;
    background: #fff;
    color: #0393d9;
    transition: ease 1s;
}

.cvr-right-inner button:hover {
    color: #fff;
    background: #0393d9;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.cvr-right-inner {
    position: absolute;
    padding: 15px 65px;
    left: -100px;
    border: 4px solid #ffffffbf;
    width: 615px;
    height: auto;
    background-image: none !important;
}

.margin-dl {
    padding: 0px;
}

@media only screen and (max-width: 1134px) {
    .cvr-right-inner {
        width: 500px;
        min-height: 435px;
    }
}

@media only screen and (max-width: 767px) {
    body {
        overflow-x: hidden!important;
    }
    .slide1 {
        background: url("../images/banner-img-1.jpg")no-repeat center;
        background-size: cover;
    }
    .slide2 {
        background: url("../images/banner-img-1.jpg")no-repeat center;
        background-size: cover;
    }
    .slide3 {
        background: url("../images/banner-img-1.jpg")no-repeat center;
        background-size: cover;
    }
    .margin-dl {
        padding: 0px;
        flex: none;
    }
    .cvr-right.animated.bounceInRight {
        width: 99.9%;
        background: unset;
        position: absolute;
        bottom: 10px;
    }
    .cvr-right-inner {
        position: absolute;
        border: unset;
        right: 0px;
    }
    .cvr-right-inner p {
        color: #000;
        font-size: 10pt;
    }
    .cvr-right-inner button {
        border: 1px solid #8bc348;
        background: #8bc348;
        color: #fff;
    }
    .slider {
        height: 370px;
        position: relative;
    }
    .cvr-right-inner {
        width: 61%;
        min-height: 435px;
        top: 125px;
        padding: 19px;
    }
    .cvr-right-inner h1 {
        color: #fff;
        font-size: 21pt;
        margin-top: 50px;
    }
    .cvr-right-inner {
        left: unset;
    }
    .cvr-right {
        padding: 45px 50px;
    }
}

section#abt-footer {
    margin-top: 3rem!important;
}

@media only screen and (max-width: 550px) {
    .sel-and-prot-in1 {
        background: #66a8de !important;
        text-align: center;
    }
    .sel-and-prot-in2 {
        background: #e96d38 !important;
        text-align: center;
    }
    .sel-and-prot-in p {
        width: 100%;
    }
}

@media only screen and (max-width: 621px) {
    .cvr-right-inner h1 {
        font-size: 17pt;
    }
}

@media only screen and (max-width: 560px) {
    .slider {
        height: 337px !important;
    }
}

@media only screen and (max-width: 500px) {
    .slider {
        height: 300px !important;
    }
}

@media only screen and (max-width: 536px) {
    cvr-right-inner {
        width: 450px;
        min-height: 435px;
        padding: 23px;
    }
    .cvr-right-inner {
        padding: 10% 10px;
    }
    .cvr-right {
        padding: 124px 50px;
    }
}

@media only screen and (max-width: 452px) {
    .cvr-right-inner h1 {
        font-size: 17pt;
        padding: 15% 0px 0px 0px;
        margin-top: 25px;
    }
    .slider {
        height: 250px !important;
    }
    .cvr-right-inner p {
        display: none;
    }
    .cvr-right-inner {
        min-height: unset;
        padding: 0px 15px;
        margin-top: 70px;
    }
    .cvr-right {
        padding: 100px 50px;
    }
}

@media only screen and (max-width: 400px) {
    .slider {
        height: 200px !important;
    }
    .cvr-right-inner {
        padding: 40px 15px;
    }
    .cvr-right-inner h1 {
        font-size: 15pt;
    }
    .cvr-right-inner button {
        padding: 3px 15px;
        font-size: 10pt;
    }
}

.qquote-sec form {
    padding: 20px 20px;
    background: #fff;
    border-radius: 30px;
    position: relative;
    top: -25px;
}

.qquote-sec select.form-control {
    border-radius: 20px;
    border: 2px solid #f58350;
    height: 45px;
    -webkit-appearance: none;
    background: #fff url('../images/sl-arrow.svg') no-repeat 90% 50%;
}

.qquote-sec input.form-control {
    border-radius: 20px;
    border: 2px solid #f58350;
    height: 45px;
}

.qquote-sec input.form-control:focus,
.qquote-sec select.form-control:focus {
    box-shadow: unset;
    outline: unset;
}

.qquote-sec button {
    width: 100%;
    padding: 8px;
    border: 1px solid #e96c37;
    border-radius: 20px;
    color: white;
    background: #e96c37;
    font-weight: 600;
}

@media only screen and (max-width: 837px) {
    .qquote-sec form {
        top: 0px;
    }
}

.sel-and-prot-in1 {
    background: url(../images/sec-bg1.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px 25px;
    height: 267px;
}

.sel-and-prot-in2 {
    background: url('../images/sec-bg2.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 20px 25px;
    height: 267px;
}

@media only screen and (max-width: 1400px) {
    .sel-and-prot-in {
        background-size: cover;
        background-position: bottom;
        border-radius: 13px;
        margin-bottom: 30px;
        height: auto;
    }
}

@media only screen and (max-width: 1199px) {
    .sel-and-prot-in1,
    .sel-and-prot-in2 {
        background-size: cover;
    }
}

@media only screen and (max-width: 767px) {
    .sel-and-prot-in1,
    .sel-and-prot-in2 {
        margin: 10px 0px;
        background-position: left;
    }
}

.sel-and-prot-in button {
    padding: 8px 14px;
    border: 2px solid #fff;
    border-radius: 20px;
    font-size: 11pt;
    color: #fff;
    background: none;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.sel-and-prot-in button:hover {
    background: #f7ab24;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.sel-and-prot-in h3 {
    font-weight: 600;
    color: #fff;
    margin: 0px;
}

.sel-and-prot-in p {
    color: #fff;
    margin: 5px 0px;
    width: 53%;
    font-size: 10pt;
}

footer {
    background: #000;
    padding: 29px 0px 0px;
    /* margin-top: 15px; */
    position: relative;
}

.prd-footer {
    margin-top: 0px;
}

footer .bg-light {
    background: none !important;
    width: -moz-available;
    /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;
    /* Mozilla-based browsers will ignore this. */
    width: fill-available;
}

footer ul.navbar-nav {
    margin: auto;
}

footer .nav-link {
    background: none !important;
    color: #fff !important;
}

footer nav ul li a:hover,
nav ul li a:visited:hover {
    font-size: 13pt;
    font-weight: 600;
    -webkit-transition: ease 1s;
    transition: ease 1s;
}

footer nav ul li a,
nav ul li a:visited {
    -webkit-transition: ease 1s;
    transition: ease 1s;
}

footer nav ul li {
    background: none;
}

footer p {
    text-align: center;
    color: #ffffffb0;
    font-size: 10pt;
    line-height: 12pt;
}

footer span {
    color: #fcdd18;
}


/* owl css */

.owl-page {
    z-index: 111;
}

.owl-controls {
    margin-top: -75px;
}

.owl-pagination {
    display: flex;
    justify-content: center;
}

.owl-page {
    height: 5px;
    width: 24px;
    border-radius: 10px;
    background-color: #d79211;
}

.owl-page:hover,
.owl-page.active {
    background-color: #ffe1a1;
}

.owl-page:not(first-item) {
    margin-left: 10px;
}

.owl-carousel {
    display: block !important;
    overflow: hidden;
}

.owl-dots {
    text-align: center;
}

.owl-dot.active {
    background: #ffe1a1;
}

.owl-dot {
    height: 5px;
    width: 28px !important;
    display: inline-block;
    border-radius: 10px;
    margin: 35px 2px 0px;
    background-color: #d79211;
}

.owl-nav {
    display: none;
}

.blog .carousel-indicators {
    left: 0;
    top: auto;
    bottom: -50px;
}


/* The colour of the indicators */

.blog .carousel-indicators li {
    background: #a7761d;
    width: 21px;
    height: 6px;
    border-radius: 14px;
}

.blog .carousel-indicators .active {
    background: #ffe1a0;
}

.carousel-indicators li {
    border: none;
}

.consoli_ins_carosel {
    /*background: url('../images/console.png');*/
}

.camera_ins_carosel {
    /*background: url('../images/camera.png');*/
}

.hphone_ins_carosel {
    /*background: url('../images/headphone.png');*/
}

.mac_ins_carosel {
    /*background: url('../images/macbook.png');*/
}

.in_carousel {
    width: 95%;
    margin: 0px 5% 0px 5%;
    /* max-height: 363px; */
    height: auto;
    background-repeat: no-repeat;
    background-size: contain;
}

.in_carousel h3 {
    color: #fff;
    font-weight: 400;
    padding: 10% 25%;
    font-size: 20pt;
}

.in_carousel button {
    padding: 3px 19px;
    border-radius: 50%;
    font-size: 20pt;
    color: hsla(0, 0%, 100%, .57);
    background: none;
    border: 3px solid;
    margin: 70% 43% 27% 43%;
    position: inherit;
    width: 52px !important;
    height: 50px !important;
}


/* @media only screen and (max-width: 1270px) {
.in_carousel button {
    margin: 50% 41%;
}
}

@media only screen and (max-width: 979px) {
.in_carousel button {
    padding: 2% 7%;
    font-size: 15pt;
    position: relative;
    bottom: -40px;
}
} */

@media only screen and (max-width: 650px) {
    .in_carousel {
        width: 70%;
        height: auto;
        background-repeat: no-repeat;
        background-size: contain;
        margin: 0px auto;
    }
    .owl-pagination {
        display: none;
    }
}

#featured {
    margin-bottom: 15px;
    margin-top: 50px;
}

.featured_in {
    background: url(../images/cat-bg.jpg);
    background-size: cover;
    padding: 15px 0px 53px;
    overflow: hidden;
}

.featured_in h2 {
    color: #fff;
    font-weight: 600;
    padding: 15px 0px;
}

@media only screen and (max-width: 1755px) {
    #featured {
        margin-top: 0px;
    }
}


/*the container must be positioned relative:*/

.custom-select {
    position: relative;
    font-family: Arial;
}

.custom-select select {
    display: none;
    /*hide original SELECT element:*/
}

.select-selected {
    background-color: DodgerBlue;
}


/*style the arrow inside the select element:*/

.select-selected:after {
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    display: inline-block;
    padding-right: 0px;
    vertical-align: middle;
    position: relative;
    left: 50%;
    font-weight: 900;
}


/*point the arrow upwards when the select box is open (active):*/

.select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}


/*style the items (options), including the selected item:*/

.select-items div,
.select-selected {
    color: #ffffff;
    padding: 8px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
}


/*style items (options):*/

.select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}


/*hide the items when the select box is closed:*/

.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.pay_safe {
    background: url(../images/sec-bg3.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 413px;
    border-radius: 10px;
    background-position: bottom;
}

.pay_safe h4 {
    color: #fff;
    font-weight: 700;
    padding-top: 30px;
}

.pay_safe p {
    color: #fff;
    max-width: 199px;
    margin: 10px auto;
}

.petnow_button {
    border-radius: 12px;
    color: #fff;
    background: none;
    font-size: 10pt;
    border: 1px solid;
    font-weight: 700;
    padding: 4px 15px;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.petnow_button a {
    color: white;
    text-decoration: none;
}

.pay_safe .petnow_button,
.mid-left button {
    border-color: #d94d54;
}

.pay_safe .petnow_button:hover,
.mid-left button :hover {
    background: #d94d54;
}

.camcorder .petnow_button,
.top1 button {
    border-color: #36386c;
}

.camcorder .petnow_button:hover,
.top1 button:hover {
    background: #36386c;
}

.smart_watch .petnow_button,
.bottom1 button {
    border-color: #0b1d38;
}

.smart_watch .petnow_button:hover,
.bottom1 button:hover {
    background: #0b1d38;
}

.listening_time .petnow_button,
.mid-right button {
    border-color: #ec7f67;
}

.listening_time .petnow_button:hover,
.mid-right button:hover {
    background: #ec7f67;
}

.camcorder {
    background: url(../images/sec-bg5.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 197px;
    background-position: left;
    margin-bottom: 10px;
    border-radius: 10px;
}

.csm_ct {
    padding: 15px 0% 0px 55%;
}

.camcorder h4 {
    color: #fff;
    font-weight: 700;
    margin: 33px 0px;
}

.smart_watch {
    background: url(../images/sec-bg4.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 204px;
    float: left;
    width: 58.7%;
    margin-right: 10px;
    border-radius: 10px;
    padding: 25px 20% 0px 30px;
}

.smart_watch h4 {
    font-weight: 700;
    color: #fff;
}

.smart_watch p {
    color: #498fc5;
    margin: 5px 0px;
}

.listening_time {
    background: url(../images/sec-bg6.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 207px;
    background-position: right;
    width: 40%;
    border-radius: 10px;
    float: left;
    padding: 10px 20px;
}

.listening_time h4 {
    color: #fff;
    font-weight: 700;
    margin-top: 25px;
    padding-right: 39%;
}

.listening_time p {
    color: #fff;
    ;
}

.no-mrg {
    margin: 0px -15px;
}

.l-mrg {
    margin-right: -3px;
    margin-left: 12px;
}

.btn-menu {
    position: absolute;
    z-index: 9999;
}

.btn-menu:focus {
    outline: 1px dotted #E0AE19;
}

.show_menu .btn-menu:focus {
    outline: none;
}

.btn-menu .text {
    display: block;
    text-indent: 150%;
    white-space: nowrap;
    overflow: hidden;
}

.btn-menu .bar,
.btn-menu .bar:before,
.btn-menu .bar:after {
    display: block;
    position: absolute;
    width: 40px;
    height: 5px;
    background-color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn-menu .bar {
    position: absolute;
    content: '';
    top: 21px;
    left: 11px;
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background: #898989;
    margin: 0px auto;
}

.visibility-visible {
    visibility: visible !important;
}

.visibility-hidden {
    visibility: hidden !important;
}

.btn-menu .bar:before {
    position: absolute;
    content: '';
    top: 0px;
    left: 12px;
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background: #898989;
    margin: 0px auto;
}

.btn-menu .bar:after {
    position: absolute;
    content: '';
    top: 0px;
    left: 24px;
    width: 8px;
    border-radius: 50%;
    height: 8px;
    background: #898989;
    margin: 0px auto;
}

.show .btn-menu .bar {
    background-color: transparent;
}

.show .btn-menu .bar:before {
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 34px;
    height: 5px;
    border-radius: 10px;
    left: 0px;
}

.show .btn-menu .bar:after {
    top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 34px;
    height: 5px;
    border-radius: 10px;
    left: 0px;
}

.overlay {
    width: 0;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8888;
    margin-top: 30px;
}

.overlay nav ul li a,
nav ul li {
    background: none;
}

.overlay nav ul li a,
nav ul li a:visited {
    background: none !important;
}

.show .overlay {
    width: 100%;
    height: 100%;
    background: #fcb500f0;
    position: fixed;
}

#mob-app-nav nav {
    padding: 0px;
}

#mob-app-nav nav ul {
    list-style-type: none;
    position: relative;
    top: 50px;
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.3s 0.2s ease-out;
}

.show #mob-app-nav nav ul {
    opacity: 1;
    transform: translateX(0);
    display: block;
}

#mob-app-nav nav a {
    display: block;
    width: 100%;
    line-height: 42px;
    text-decoration: none;
    color: white;
    font-weight: 500;
    text-align: center;
}

.overlay .fab {
    color: #feb600;
}

.social-icon-wrap {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    margin: 0 6px;
    box-shadow: 0px 2px 15px 1px rgba(62, 62, 62, .2);
}

.call_mob {
    display: none;
}

.social-icon-wrap:hover i {
    color: #fff;
}

.mb-sc {
    margin-top: 10px;
    border-top: 1px solid #0393d9;
}

.fb-wrap:hover {
    background-color: #3b5998;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.tw-wrap:hover {
    background-color: #38a1f3;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.gp-wrap:hover {
    background-color: #cc3333;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.ln-wrap:hover {
    background-color: #0077b5;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

@media only screen and (max-width: 1772px) {
    .csm_ct {
        padding: 15px 0% 0px 44%;
    }
}

@media only screen and (max-width: 1550px) {
    .csm_ct {
        padding: 15px 0% 0px 50%;
    }
    .smart_watch {
        height: 195px;
    }
    .pay_safe {
        height: 402px;
    }
    .listening_time {
        height: 195px;
    }
}

@media only screen and (max-width: 1515px) {
    .smart_watch {
        height: 196px;
    }
    .pay_safe {
        height: 403px;
    }
}

@media only screen and (max-width: 1500px) {
    .smart_watch {
        height: 190px;
    }
    .pay_safe {
        height: 397px;
    }
    .listening_time {
        height: 190px;
    }
}

@media only screen and (max-width: 1427px) {
    .smart_watch {
        height: 183px;
    }
    .pay_safe {
        height: 390px;
    }
    .listening_time {
        height: 183px;
        background-position: initial;
    }
}

@media only screen and (max-width: 1401px) {
    .pay_safe {
        height: 387px;
    }
    .smart_watch,
    .listening_time {
        height: 180px;
    }
}

@media only screen and (max-width: 1251px) {
    .camcorder {
        height: 185px;
    }
    .csm_ct {
        padding: 10px 0px 0px 55%;
    }
    .listening_time {
        width: 48.6%;
        height: 191px;
    }
    .smart_watch {
        height: 191px;
        width: 50%;
        padding: 25px 10% 0px 30px;
    }
}

@media only screen and (max-width: 1220px) {
    .smart_watch h4,
    .camcorder h4 {
        font-size: 15pt !important;
    }
    #display_sec p {
        font-size: 10pt;
    }
}

@media only screen and (max-width: 991px) {
    .in_carousel h3 {
        font-size: 12pt;
    }
    .in_carousel button {
        top: 50px;
        position: relative;
        font-size: 15pt;
    }
    .owl-controls {
        margin-top: -25px;
    }
    .select:after {
        right: 18px !important;
    }
}

@media only screen and (max-width: 767px) {
    #blogCarousel {
        width: 220px;
        margin: 0px auto;
    }
    .in_carousel button {
        padding: 2% 6%;
    }
    .listening_time h4 {
        margin-top: 35px;
    }
}

@media only screen and (max-width: 650px) {
    .in_carousel button {
        padding: 4% 7%;
        top: 70px;
    }
}

@media screen and (min-width:1200px) {
    .in_carousel h3 {
        font-size: 16pt;
        min-height: 130px;
    }
}

@media screen and (max-width:1199px) {
    .in_carousel h3 {
        padding: 10% 10%;
        font-size: 17pt;
        min-height: 100px;
    }
}

@media screen and (max-width:820px) {
    .in_carousel h3 {
        min-height: 80px;
    }
}

@media screen and (max-width:767px) {
    .in_carousel h3 {
        font-size: 15pt;
    }
}

@media screen and (max-width:405px) {
    .in_carousel h3 {
        font-size: 13pt;
    }
    .in_carousel button {
        padding: 2% 7%;
    }
}

@media only screen and (max-width: 469px) {
    .in_carousel button {
        padding: 2% 6%;
        top: 49px;
    }
}

@media only screen and (max-width: 1199px) {
    #display_sec h4 {
        font-size: 12pt;
    }
    #display_sec p {
        font-size: 10pt;
    }
    .listening_time {
        width: 43%;
        background-position: bottom;
    }
    .camcorder {
        background-size: cover;
        background-position: 20%;
        border-radius: 10px;
    }
    .smart_watch {
        background-position: 60%;
        width: 55%;
    }
    .smart_watch p {
        display: none;
    }
}

@media only screen and (max-width: 1143px) {
    .smart_watch {
        padding: 20px 20% 0px 20px;
    }
}

@media only screen and (max-width: 904px) {
    #display_sec h4 {
        font-size: 12pt !important;
    }
}

@media only screen and (max-width: 991px) {
    .container {
        max-width: 100%;
    }
}

@media only screen and (max-width: 880px) {
    .smart_watch p,
    .listening_time p {
        display: none;
    }
    #display_sec h4 {
        font-size: 15pt;
    }
}

@media only screen and (max-width: 869px) {
    .smart_watch,
    .listening_time {
        background-position: bottom;
    }
}

@media only screen and (max-width: 767px) {
    .camcorder {
        max-height: 160px;
    }
    #display_sec h4 {
        font-size: 12pt;
        margin: 2px;
    }
    .smart_watch {
        max-height: 117px;
    }
}

@media only screen and (max-width: 575px) {
    .pay_safe {
        height: 165px;
    }
    .camcorder {
        text-align: right;
        max-height: 124px;
        padding: 26px;
        position: relative;
        left: 23px;
        margin: 10px;
    }
    .smart_watch {
        max-height: 117px;
        position: relative;
        left: 30px;
        max-height: fit-content;
    }
    .listening_time {
        padding: 26px 10px;
        position: relative;
        margin-top: 10px;
        left: 30px;
    }
    .sel-and-prot-in1,
    .sel-and-prot-in2 {
        background-size: cover;
        border-radius: 15px;
        margin: 10px;
    }
}

@media only screen and (max-width: 452px) {
    .camcorder {
        padding: 10px;
        max-height: fit-content;
    }
}

@media only screen and (max-width: 400px) {
    .camcorder {
        padding: 10px;
        max-height: min-content;
        width: 324px;
    }
    .listening_time {
        padding: 9px 10px;
    }
    #display_sec h4 {
        padding: 5px;
        font-size: 10pt;
    }
}

@media only screen and (max-width: 357px) {
    #display_sec h4 {
        padding: 0px;
    }
}

@media only screen and (max-width: 340px) {
    #display_sec h4 {
        padding: 0px;
        font-size: 9pt;
    }
}

#display_sec_sm {
    display: none;
}

@media only screen and (max-width: 799px) {
    #display_sec {
        display: none;
    }
    #display_sec_sm {
        display: block;
        width: 97.5%;
        padding-left: 10px;
    }
    .in_carousel {
        width: 90%;
    }
}

@media only screen and (max-width: 650px) {
    .in_carousel {
        width: 50%;
    }
    .in_carousel button {
        padding: 2% 6%;
        top: 75px;
        position: relative;
    }
}

@media only screen and (max-width: 460px) {
    .in_carousel {
        width: 60%;
    }
    .in_carousel button {
        padding: 1% 6%;
        bottom: -15px;
    }
}

.toptxt {
    position: relative;
    padding-left: 50%;
    padding-top: 6%;
}

.top1 {
    background: url(../images/banner-1.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 187px;
    background-position: left;
    border-radius: 11px;
    margin: 15px;
}

.toptxt h4 {
    font-weight: 700;
    color: #fff;
}

.mid-left {
    background: url(../images/banner-2.png);
    background-size: cover;
    height: 184px;
    margin-left: 20px;
    border-radius: 10px;
    padding: 15px;
    background-position: center;
    color: #fff;
}

.mid-left h4 {
    font-weight: 700;
}

.mid-right {
    background: url(../images/banner-3.png);
    background-size: cover;
    height: 184px;
    margin-right: 20px;
    border-radius: 10px;
    padding: 15px;
    background-position: right;
    color: #fff;
}

.mid-right h4 {
    font-weight: 700;
}

.bottom1 {
    background: url(../images/banner-4.png);
    background-size: cover;
    height: 184px;
    margin: 10px;
    border-radius: 10px;
    padding: 15px;
    background-position: bottom;
    color: #fff;
}

.bottom1 h4 {
    font-weight: 700;
}

.mobmarg {
    padding: 0px 5px;
}

@media only screen and (max-width: 704px) {
    #display_sec_sm h4 {
        font-size: 12pt;
    }
    #display_sec_sm p {
        font-size: 10pt;
    }
}

@media only screen and (max-width: 575px) {
    .top1 {
        background: #21164c;
        height: auto;
        border-radius: 11px;
        margin: 15px;
        padding: 25px;
    }
    .toptxt {
        position: relative;
        padding-left: 0%;
        padding-top: 0%;
        text-align: center;
    }
    .mid-left {
        background: #c02b30;
        height: auto;
        margin: 0px 20px 10px;
        border-radius: 10px;
        padding: 25px;
        color: #fff;
        text-align: center;
    }
    .mid-right {
        background: #e55d3f;
        height: auto;
        margin: 5px 20px 10px;
        border-radius: 10px;
        padding: 25px;
        color: #fff;
        text-align: center;
    }
    .bottom1 {
        background: #04000f;
        height: auto;
        margin: 5px 10px 10px;
        border-radius: 10px;
        padding: 25px;
        color: #fff;
        text-align: center;
    }
    #display_sec_sm h4 {
        font-size: 16pt;
    }
    footer {
        margin-top: 30px;
    }
}


/* about us page css starts here */

#aboutus {
    padding-top: 75px;
    overflow: hidden;
}

#aboutus h1 {
    font-weight: 600;
    color: #444444;
    font-size: 40pt;
}

#aboutus p {
    color: #777777;
    width: 90%;
    line-height: 21pt;
    font-size: 13pt;
}

#aboutus button {
    padding: 10px 30px;
    color: #0393d9;
    background: #fff;
    border: 2px solid #77777747;
    border-radius: 30px;
    font-weight: 500;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

#aboutus button:hover {
    background: #0393d9;
    color: #fff;
    border: #0393d9;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

#aboutus .abt-right img {
    max-width: 100%;
    height: 636px;
    position: relative;
    right: 20%;
    padding: 50px;
    margin-top: 5%;
}

#aboutus .abt-left {
    padding: 10% 20%;
}

#aboutus .abt-right {
    background: #0393d9;
    height: auto;
}

#aboutus .here-help-right h1 {
    color: #f6c74c;
}

#aboutus .here-help-right {
    padding: 10% 11px;
}

#aboutus .here-help {
    /* padding-top: 100px; */
}

#aboutus .abt-footer {
    background: url('../images/footer-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    margin-bottom: -15px;
}

#aboutus .abt-footer p {
    color: #fff;
}

#aboutus .abt-footer button {
    background-color: unset;
    color: #fff;
    border-color: #fff;
    -webkit-transition: ease 2s;
    transition: ease 1s;
    border: 1px solid;
}

#aboutus .abt-footer button:hover {
    background: #fff;
    color: #0393d9;
    border: #0393d9;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

@media only screen and (max-width: 1120px) {
    #aboutus .abt-right img {
        height: auto;
    }
}

@media only screen and (max-width: 991px) {
    #aboutus .abt-left {
        padding: 10% 5%;
    }
    .prd-page .how-help-right {
        border-radius: 0px !important;
        padding: 5% 8% !important;
    }
}

@media only screen and (max-width: 799px) {
    .abt-right {
        height: auto !important;
    }
    #aboutus .abt-right img {
        height: auto;
        right: 0%;
    }
    #aboutus p {
        width: 100%;
    }
    #aboutus .abt-left {
        padding: 10% 10%;
    }
}

@media only screen and (max-width: 575px) {
    #aboutus .abt-footer {
        margin-bottom: 0px;
    }
}


/* contact page css starts here */

#contact_sec {
    padding-top: 75px;
    margin-bottom: -20px;
}

#contact_sec .contact_left {
    background: #323431;
    padding: 10% 15%;
}

.contact_right p {
    font-weight: 400;
}

#contact_sec .contact_left h1 {
    color: #fff;
    font-weight: 700;
}

#contact_sec .contact_left p {
    color: #f4bd30;
}

#contact_sec .contact_left label {
    color: #fff;
}

#contact_sec .form-control {
    background: none;
    border-bottom: 1px solid #0393d9;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0px;
    color: #fff;
}

#contact_sec .contact_left button {
    padding: 10px 35px;
    border-radius: 30px;
    border: none;
    background: #0393d9;
    color: #fff;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

#contact_sec .contact_left button:hover {
    background: #fff;
    color: #0393d9;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

#contact_sec .contact_right {
    background: #fff;
    padding: 10% 15%;
}

#contact_sec td {
    vertical-align: top;
    padding: 10px;
    color: #777777;
}

#contact_sec td img {
    max-width: 45px;
}

#contact_sec h4 {
    font-weight: 600;
    color: #0393d9;
}

#contact_sec .contact_tb {
    border-bottom: 1px solid #0000002e;
}

#contact_sec .text-bold {
    font-weight: 500;
}

@media only screen and (max-width: 840px) {
    #contact_sec .contact_right {
        padding: 10% 10%;
    }
}

@media only screen and (max-width: 575px) {
    #contact_sec {
        margin-bottom: 0px;
    }
    #contact_sec .timing_td {
        display: inline-block;
    }
}


/* product page css starts here */

.prd-page {
    padding-top: 75px;
    overflow: hidden;
}

.prd-page .prd-cvr {
    background: url(../images/pr-banner-img.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 12%;
}

.prd-page .prd-cvr h1 {
    font-weight: 800;
    color: #fff;
}

.prd-page .prd-cvr h4 {
    color: #fff;
}

.prd-page .prd-cvr button {
    padding: 10px 15px;
    color: #fff;
    background: #8bc348;
    border: none;
    border-radius: 20px;
    font-weight: 700;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.prd-page .prd-cvr button:hover {
    background: #fff;
    color: #8bc348;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.col-xs-6.col-sm-6.col-md-6.col-lg-6 {
    width: 50%;
}

@media only screen and (max-width: 799px) {
    .prd-page .prd-cvr {
        padding: 5%;
    }
}

@media only screen and (max-width: 600px) {
    .prd-page .prd-cvr h4 {
        display: none;
    }
    .prd-page .prd-cvr h1 {
        font-size: 18pt;
    }
    .prd-page .prd-cvr p {
        font-size: 10pt;
    }
    .prd-page .prd-cvr button {
        padding: 8px 15px;
        font-size: 10pt;
    }
}

@media only screen and (max-width: 400px) {
    .prd-page .prd-cvr p {
        font-size: 8pt;
    }
    .prd-page .prd-cvr button {
        padding: 8px 10px;
        font-size: 8pt;
    }
}

.tab-mb {
    display: none;
}

.prd-page .prt-prd {
    padding: 50px 0px;
}

.prd-page .prt-prd h2 {
    font-weight: 700;
}

.prd-page .prt-prd p {
    color: #f6c74e;
}

.prd-page .prt-prd h5 {
    color: #f6c74e;
    font-weight: 600;
}

.prd-page .prt-prd .prt-left p,
.prd-page .prt-prd .prt-right p {
    color: #000;
    font-size: 10pt;
    font-weight: 400;
}

.prd-page .prt-prd .prt-left span {
    padding-left: 10px;
}

.prd-page .prt-prd .prt-right span img {
    padding-right: 10px;
}

.prt-left,
.prt-right {
    padding-top: 32%;
}

.prd-page .prt-prd button {
    padding: 10px 30px;
    border: 2px solid #8dbf4b;
    color: #fff;
    border-radius: 20px;
    background: #8dbf4bd4;
    font-weight: 600;
    transition: ease 1s;
}

.prd-page .prt-prd button:hover {
    color: #fff;
    background: #8dbf4b;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.img-fit {
    max-width: 100%;
    height: fit-content;
}

.prd-page .sprt-svg {
    position: absolute;
    top: 0px;
    right: -18px;
    height: 100%;
}

.prd-page .how-help-right {
    background: #f4bd30;
    height: 110%;
    border-radius: 0px 0px 0px 40px;
    padding: 40px 0px;
}

.prd-page .how-help-right h2 {
    font-weight: 700;
    color: #fff;
}

.prd-page .how-help-right p {
    padding-right: 10%;
    color: #fff;
    font-size: 14pt;
}

.prd-page .how-help-right td {
    vertical-align: top;
    font-size: 10pt;
    color: #fff;
}

.prd-page .how-help-right td img {
    max-width: 55px;
    margin-right: 5px;
}

@media only screen and (max-width: 767px) {
    .prd-page .prt-left {
        text-align: center !important;
    }
    .prd-page .prt-prd .prt-left span {
        display: none
    }
    .prt-left,
    .prt-right {
        padding-top: 0px;
    }
    .prt-center {
        text-align: center;
    }
    .prd-page .prt-right {
        text-align: center !important;
    }
    .prd-page .prt-prd .prt-right span img {
        display: none
    }
    .hidden-xs,
    .hidden-sm {
        display: none;
    }
    .prd-page .prt-left,
    .prd-page .prt-right {
        display: none;
    }
    .tab-mb {
        display: block;
    }
    .prd-page .prt-prd .nav-item h5 {
        font-weight: bold;
        font-size: 10pt;
        color: #000;
    }
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: #495057;
        background-color: #f6c74e;
        border-color: #f6c74e #f6c74e #f6c74e;
    }
    .prd-page .prt-prd .tab-content p {
        color: #000;
    }
    .prd-page .prt-prd .tab-content span img {
        padding-right: 7px;
    }
    .prd-page .prt-prd .nav-tabs {
        padding-top: 15%;
    }
}

@media only screen and (max-width: 360px) {
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link {
        padding: 9px 4px;
    }
}

.sp-bg {
    background: url('../images/support-img.jpg');
    background-size: cover;
}

.prd-page .prd-what {
    padding: 100px 0px;
}

.prd-page .prd-what-left img {
    max-width: 95%;
}

.prd-page .prd-what-right {
    padding: 7% 15% 7% 0%;
}

.prd-page .prd-what-right h2 {
    font-weight: 600;
}

.prd-page .prd-what-right p {
    line-height: 22pt;
    font-size: 12.8pt;
    color: #000000c2;
}

.prd-page .prd-what-right h4 {
    font-weight: 500;
    color: #f9bf41;
}

.prd-page .prd-what-right img {
    padding-right: 10px;
}

@media only screen and (max-width: 767px) {
    .prd-page .prd-what-left img {
        max-width: 80%;
    }
    .prd-page .prd-what-right {
        padding: 10%;
    }
}

.prd-page .abt-footer {
    background: url('../images/footer-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px;
    margin-bottom: -15px;
}

.prd-page .abt-footer p {
    color: #fff;
    font-size: 13pt;
}

.prd-page .abt-footer h2 {
    font-weight: 600;
    color: #fff;
}

.prd-page .abt-footer button {
    background-color: unset;
    color: #fff;
    -webkit-transition: ease 2s;
    transition: ease 1s;
    border: 2px solid;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 20px;
}

.prd-page .abt-footer button:hover {
    background: #fff;
    color: #0393d9;
    -webkit-transition: ease 2s;
    transition: ease 1s;
}

.pdf-sec {
    position: relative;
    top: 100px;
    margin: 0px auto;
    background: #404040;
    border-radius: 10px 10px 0px 0px;
    width: max-content;
    padding: 13px 50px 3px;
}

.pdf-sec p {
    color: #fff;
    font-weight: 500;
}

.pdf-sec span {
    color: #f9c649;
}

.pdf-sec .spanbd {
    font-weight: 600;
    color: #fff;
}

.pdf-sec span b {
    color: #fff;
}

.pdf-sec img {
    padding-right: 10px;
}

.brdr-r {
    border-right: 1px solid white;
}

.pdf-sec td {
    padding: 2px 10px;
}

@media only screen and (max-width: 515px) {
    .pdf-sec td {
        display: table;
        margin: 10px 0px;
    }
}


/* pg-product page css starts here */

#pg-produc {
    padding-top: 75px;
    background: #fafafa;
    overflow-x: hidden;
}

.gen-your-quote {
    min-height: 800px;
}

.gen-your-quote h3 {
    font-weight: 600;
}

#pg-produc h3 {
    font-weight: 600;
}

#pg-produc p {
    color: #333;
}

.gen-your-quote {
    /* padding-right: 10%; */
    padding-top: 15px;
}

.pd-steps i.fas.fa-angle-right {
    position: relative;
    left: 5px;
    color: #fff;
}

.pg-details-step {
    padding: 13px;
    background: #0393d9;
    border-radius: 6px;
    margin-bottom: 40px;
}

.pd-steps .activespan {
    background: #ffffff;
    font-size: 10pt;
    padding: 5px;
    border-radius: 20px;
    color: #0393d9;
    margin-right: 5px;
}

.pd-steps span {
    background: #00000036;
    font-size: 10pt;
    padding: 5px 10px;
    border-radius: 30px;
    color: #fff;
    margin-right: 5px;
    font-weight: 600;
    width: 30px;
    display: inline-block;
    height: 30px;
    position: relative;
    top: -1px;
    text-align: center;
}
.pg-details-step .prvstep i {
    position: relative;
    top: 3px;
}
.pd-steps {
    font-size: 9pt;
    color: #315263;
    font-weight: 600;
}

.pd-steps.active {
    color: #fff;
    font-weight: bold;
}

.gen-your-quote select.form-control {
    border-radius: 20px;
    border: 2px solid #cecece94;
    height: 45px;
    -webkit-appearance: none;
    padding-left: 20px;
    background: #fff url('../images/fm-ar.svg') no-repeat 90% 50%;
}

.gen-your-quote select.form-control:active {
    border-color: #0393d9;
}

#pg-produc form {
    margin: 20px 0px;
}

/* #pg-produc .form-group {
    margin-bottom: 0px;
} */

#pg-produc .product-form {
    padding: 20px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 7px rgb(221 221 221 / 52%);
}

#pg-produc .product-form h5 {
    font-weight: 500;
    padding: 20px 0px;
}

button.dlt-gdt.remove_field {
    color: red !important;
    padding: 7px 17px !important;
    background: #fff1f1 !important;
    border: none !important;
    border-radius: 30px !important;
    width: fit-content !important;
    font-size: 10pt !important;
    font-weight: unset !important;
}

#pg-produc .product-form .dlt-gdt {
    color: red;
    padding: 7px 17px;
    background: #fff1f1;
    border: none;
    border-radius: 30px;
    width: fit-content;
    font-size: 10pt;
    position: relative;
    left: 50%;
    top: 20%;
}

#pg-produc .bottom-btns button {
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
}

#pg-produc .bottom-btns .ad_gad {
    background: #fff;
    color: #0569c9;
}

#pg-produc .bottom-btns .nxt_btn {
    background-image: -moz-linear-gradient(135deg, #5aad31 0%, #a5de61 100%);
    background-image: -webkit-linear-gradient(135deg, #5aad31 0%, #a5de61 100%);
    background-image: -ms-linear-gradient(135deg, #5aad31 0%, #a5de61 100%);
    background-image: linear-gradient(135deg, #5aad31 0%, #a5de61 100%);
}
#pg-produc .bottom-btns button.back_btn{
    background: #065bad;
    color: rgb(255 255 255 / 80%);
}
.add_gadget {
    margin-right: 300px;
}

.wrap {
    position: relative;
    width: 100%;
    left: 0;
    margin-bottom: 10px;
}

.select-text {
    position: relative;
    background-color: transparent;
    width: 100%;
    padding: 10px 10px 10px 20px;
    font-size: 18px;
    border-radius: 35px;
    border: 2px solid #e2e2e2 !important;
}


/* Remove focus */

.select-text:focus {
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}


/* Use custom arrow */

.select .select-text {
    appearance: none;
    -webkit-appearance: none
}

.select:after {
    position: absolute;
    top: 15px;
    right: 35px;
    width: 20px;
    height: 20px;
    padding: 0;
    content: '';
    background: #fff url(../images/fm-ar.svg) no-repeat 90% 50%;
    pointer-events: none;
}


/* LABEL ======================================= */

.select-label {
    color: #6c757d;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 20px !important;
    top: 10px;
    transition: 0.2s ease all;
}

.select-label-al {
    color: red !important;
}


/* active state */

.select-text:focus~.select-label,
.select-text:valid~.select-label {
    color: #0393d9;
    transition: 0.2s ease all;
    font-size: 14px;
    top: -10px !important;
    background: #fff;
    padding: 0px 10px;
}

.select-text:focus~.select-label #addrlabel,
.select-text:valid~.select-label #addrlabel {
    top: -20px !important;
}


/* BOTTOM BARS ================================= */

.select-bar {
    position: relative;
    display: block;
    width: 350px;
}

.select-bar:before,
.select-bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #2F80ED;
    transition: 0.2s ease all;
}

.select-bar:before {
    left: 50%;
}

.select-bar:after {
    right: 50%;
}


/* active state */

.select-text:focus~.select-bar:before,
.select-text:focus~.select-bar:after {
    width: 50%;
}


/* HIGHLIGHTER ================================== */

.select-highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

@media only screen and (max-width: 799px) {
    .gen-your-quote {
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 991px) {
    #pg-produc .hide-sm {
        display: none;
    }
    .pd-steps {
        display: none;
    }
    .pd-steps.mbactive {
        display: block;
        text-align: center;
        font-size: 9pt !important;
    }
    #pg-produc .product-form .dlt-gdt {
        left: 0px;
    }
}

@media only screen and (max-width: 575px) {
    #pg-produc .col-xs-6 {
        width: 50%;
    }
}

.model-other {
    display: none;
}


/* pg-yourdetails page css starts here */

#pg-yourdt {
    padding-top: 75px;
    overflow-x: hidden;
}

/* #pg-yourdt #sl_address {
    display: none;
} */

.pg-details-step .prvstep {
    background: #66bd53;
    padding: 6px 8px;
}

@media only screen and (max-width: 991px) {
    #pg-yourdt .hide-sm {
        display: none;
    }
    .pd-yourdt {
        display: none;
    }
    .pd-yourdt.active {
        display: block;
        text-align: center;
    }
    #pg-produc p,
    #pg-produc h3 {
        text-align: center;
    }
    .notes h3,
    .notes p{
        text-align: left !important;
    }
}

#pg-yourdt .pl-dt-form {
    padding: 0 0px 30px;
}

.bdinput {
    border: 2px solid #e2e2e2 !important;
    border-radius: 35px !important;
    height: 31px !important;
    padding-left: 20px !important;
    width: -webkit-fill-available;
    width: -moz-available;
}

.bdinput:focus-within,
.select-text:focus-within {
    border-color: #0393d9 !important;
}

#pg-produc .md-form {
    margin: 0px 0px;
}

.md-form {
    margin: 10px 0px;
}

.bd-label {
    position: relative;
    left: 20px !important;
}

.bd-label .active {
    color: red !important;
}

.md-form input:not([type]):focus:not([readonly]),
.md-form input[type=date]:not(.browser-default):focus:not([readonly]),
.md-form input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
.md-form input[type=datetime]:not(.browser-default):focus:not([readonly]),
.md-form input[type=email]:not(.browser-default):focus:not([readonly]),
.md-form input[type=number]:not(.browser-default):focus:not([readonly]),
.md-form input[type=password]:not(.browser-default):focus:not([readonly]),
.md-form input[type=search-md]:focus:not([readonly]),
.md-form input[type=search]:not(.browser-default):focus:not([readonly]),
.md-form input[type=tel]:not(.browser-default):focus:not([readonly]),
.md-form input[type=text]:not(.browser-default):focus:not([readonly]),
.md-form input[type=time]:not(.browser-default):focus:not([readonly]),
.md-form input[type=url]:not(.browser-default):focus:not([readonly]),
.md-form textarea.md-textarea:focus:not([readonly]) {
    color: black !important;
    box-shadow: unset !important;
}

.md-form input:not([type]):focus:not([readonly])+label,
.md-form input[type=date]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=datetime]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=email]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=number]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=password]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=search-md]:focus:not([readonly])+label,
.md-form input[type=search]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=tel]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=text]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=time]:not(.browser-default):focus:not([readonly])+label,
.md-form input[type=url]:not(.browser-default):focus:not([readonly])+label,
.md-form textarea.md-textarea:focus:not([readonly])+label {
    color: #0393d9 !important;
    background: #fff;
    padding: 0px 20px;
}

.md-form label.active {
    background: #fff;
    padding: 0px 20px;
    color: #0393d9;
}

#pg-yourdt .wrap {
    margin: 10px 0px;
}

.date-bd-label {
    top: 19px !important;
    background: #fff !important;
    margin: 0px 20px !important;
}

#pg-yourdt .post-find {
    position: absolute;
    right: 4.8%;
    top: 14%;
    background: #0393d9;
    border: none;
    border-radius: 4px;
    padding: 5px 15px;
    color: #fff;
    font-weight: 400;
}

#pg-yourdt .bottom-btns .nxt_btn {
    background: #0393d9;
}

#pg-yourdt .bottom-btns .back_btn {
    background: #dfdfdf;
    color: #636363;
}

#pg-yourdt .bottom-btns button {
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
}

.fm_er {
    position: absolute;
    font-size: 10pt;
    top: 100%;
    right: 10%;
    color: red;
}

.select-text:focus~#addrlabel {
    margin-top: -10px;
}

@media only screen and (max-width: 550px) {
    #pg-yourdt .post-find {
        left: 80%;
    }
    .sel-and-prot-in1 {
        background: #66a8de;
        text-align: center;
    }
    .sel-and-prot-in2 {
        background: #e96d38;
        text-align: center;
    }
    .sel-and-prot-in p {
        width: 100%;
    }
}

@media only screen and (max-width: 450px) {
    #pg-yourdt .post-find {
        left: 70%;
    }
}


/* quote summary css starts here */

.qu-left {
    padding: 10px 15px;
    background: #fff;
    /* border-radius: 15px; */
    margin: 10px 0px;
    border-radius: 6px;
    box-shadow: 0px 0px 7px rgb(221 221 221 / 52%);

}

.qu-left table th {
    font-weight: 600;
}

.qu-left table.table thead th {
    border-top: none;
    border-bottom: 2px solid #0393d9;
}

.qu-left td {
    color: #898989;
    font-weight: 400 !important;
    border-color: #efefef;
}

.qu-left td button {
    color: red;
    background: #fff1f1;
    border: none;
    border-radius: 30px;
    width: fit-content;
    font-size: 10pt;
}

.usl-payment {
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    border: none;
}

.usl-payment h3 {
    font-weight: 700;
    font-size: 13pt;
    color: #fd651a;
}

.usl-payment h2 {
    font-weight: 700;
    color: #ffd24b;
    font-size: 30pt;
}

.usl-payment h4 {
    font-weight: 600;
    font-size: 13pt;
    color: #96cd56;
}

.usl-payment p {
    font-size: 10pt;
    font-weight: 500;
    color: #c9c9c9;
}

.input.c_radio {
    opacity: 0;
    position: absolute;
}

.c_radio:checked~.usl-payment {
    padding: 10px;
    background: #fffefc;
    border-radius: 10px;
    border-style: solid;
    border-color: #33b5e5;
}

.c_radio:checked~.usl-payment h3 {
    font-weight: 700;
    font-size: 13pt;
    color: #000;
}

.c_radio:checked~.usl-payment h2 {
    font-weight: 700;
    color: #0393d9;
    font-size: 30pt;
}

.c_radio:checked~.usl-payment h4 {
    font-weight: 600;
    font-size: 13pt;
    color: #000;
}

.c_radio:checked~.usl-payment p {
    font-size: 10pt;
    font-weight: 500;
    color: #c9c9c9;
}

.payment-btn {
    padding: 12px 20px;
    font-size: 13pt;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-weight: 500;
    max-width: max-content;
    display: inline-block;
    background-image: -moz-linear-gradient(135deg, #5aad31 0%, #a5de61 100%);
    background-image: -webkit-linear-gradient(135deg, #5aad31 0%, #a5de61 100%);
    background-image: -ms-linear-gradient(135deg, #5aad31 0%, #a5de61 100%);
    background-image: linear-gradient(135deg, #5aad31 0%, #a5de61 100%);
}

.payment-back {
    border-radius: 5px;
    border: none;
    padding: 12px 20px;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    background: #065bad;
    color: #fff;
}

.payment-back:hover {
    color: #fff;
}

@media only screen and (max-width: 1345px) {
    .y-payment h2,
    .usl-payment h2 {
        font-size: 20pt;
    }
    .qu-left td,
    .qu-left th {
        padding: 10px 2px !important;
        font-size: 10pt !important;
    }
}


/* payment page css starts here */

.payment-method h5 {
    font-weight: 500;
    color: #3a3e3d;
}

.sl_icon {
    position: absolute;
    color: #fff;
    font-size: 16pt;
    background: #0393d9;
    padding: 10px 10px;
    /* bottom: 35%; */
    border-radius: 50px;
    display: none;
    left: auto !important;
    right: 0;
    bottom: auto;
    top: -10px;
}

.p-option {
    background: #fff;
    border: 3px solid #fff !important;
    border-radius: 20px;
    color: #3a3e3d;
}

.sl_pay {
    border: 3px solid #0393d9 !important;
    background: #f8fdf7;
}

.dd-form,
.cd-form {
    padding: 20px 20px 0 20px;
    background: #fff;
    border-radius: 0;
    display: none;
    border-radius: 6px;
    box-shadow: 0px 0px 7px rgb(221 221 221 / 52%);
}

.dd-form .md-form,
.cd-form .md-form {
    margin: 10px 0px !important;
}

.dd-form .wrap {
    margin: 10px 0px !important;
}

.cd-form label,
.dd-form label {
    top: 0 !important;
}

.dd-form .wrap label {
    top: 10px !important;
}

button.btn.w-100.waves-effect.waves-light.collapsed,
.payment-method .btn {
    background: white !important;
    color: #0393d9;
    text-align: left;
    border: 1px solid #ced4da;
    text-transform: initial;
    padding: 15px 15px;
    border-radius: 6px;
    position: relative;
    left: -6px;
    font-size: 16px;
    font-weight: 700;
}

.acc-ico {
    position: absolute;
    right: 10px;
    top: 13px;
}

.acc-ico2 {
    position: absolute;
    right: 10px;
    top: 13px;
    transform: rotate(180deg);
}
.documents,
.documents a {
    color: #0393d9;
    text-decoration: underline;
}

.documents span {
    padding-right: 10px;
    vertical-align: bottom;
}

@media only screen and (max-width: 1345px) {
    .sl_icon {
        left: 10%;
    }
}

@media only screen and (max-width: 991px) {
    .payment-method p {
        text-align: left !important;
    }
    .payment-method .text-right {
        text-align: center !important;
    }
}

@media only screen and (max-width: 767px) {
    .p-option {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 410px) {
    .p-option {
        font-size: 9pt;
    }
}


/*  Quote Summary Style*/


/* success page css starts here */

i.fa.fa-check.sucess-ico {
    background: #66bd53;
    color: #fff;
    font-size: 15pt;
    padding: 15px;
    border-radius: 57px;
    box-shadow: 0px 0px 0px 14px #66bd5324;
}

.sucess-sec h2 {
    color: #66bd53;
}

.sucess-sec button {
    padding: 15px 25px;
    color: #777777;
    background: #e9e9e9;
    font-weight: 600;
    border: none;
    border-radius: 30px;
}

@media (min-width: 768px) and (max-width: 1134px) {
    body {
        overflow-x: hidden!important;
    }
    .cvr-right-inner p {
        display: none;
    }
    .cvr-right-inner {
        min-height: auto;
        width: 400px;
    }
    nav ul li a,
    nav ul li a:visited {
        padding: 0px 10px;
    }
}

@media (min-width: 992px) and (max-width: 1412px) {
    .select:after {
        right: 15px;
        background: url(../images/fm-ar.svg) no-repeat 90% 50%;
        background-size: 14px;
    }
}

.quote-summary-sec button.mt-5.w-50.payment-btn {
    display: none;
}

.dob label {
    top: 0px !important;
}

@media (max-width: 1191px) {
    .pd-steps {
        font-size: 8pt !important;
    }
    .select:after {
        right: 10px;
    }
}

@media (max-width: 1106px) {
    .pd-steps {
        font-size: 7pt !important;
    }
}

@media (max-width: 1021px) {
    .pd-steps {
        font-size: 6pt !important;
    }
}

@media (min-width: 768px) and (max-width: 1238px) {
    .qquote-sec select.form-control,
    .qquote-sec input.form-control {
        font-size: 10pt !important;
    }
    .qquote-sec .col-xs-6.col-sm-6.col-md-2.col-lg-2 {
        margin: 0px !important;
        padding: 3px 3px !important;
    }
    /* radio button */
    usl-payment {
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        border: none;
    }
    .usl-payment h3 {
        font-weight: 700;
        font-size: 13pt;
        color: #fd651a;
    }
    .usl-payment h2 {
        font-weight: 700;
        color: #ffd24b;
        font-size: 30pt;
    }
    .usl-payment h4 {
        font-weight: 600;
        font-size: 13pt;
        color: #96cd56;
    }
    .usl-payment p {
        font-size: 10pt;
        font-weight: 500;
        color: #c9c9c9;
    }
    input.c_radio {
        opacity: 0;
        position: absolute;
    }
    .c_radio:checked~.usl-payment {
        padding: 10px;
        background: #ffd24b;
        border-radius: 10px;
    }
    .c_radio:checked~.usl-payment h3 {
        font-weight: 700;
        font-size: 13pt;
        color: #000;
    }
    .c_radio:checked~.usl-payment h2 {
        font-weight: 700;
        color: #fc6012 !important;
        font-size: 30pt;
    }
    .c_radio:checked~.usl-payment h4 {
        font-weight: 600;
        font-size: 13pt;
        color: #000;
    }
    .c_radio:checked~.usl-payment p {
        font-size: 10pt;
        font-weight: 500;
        color: #c9c9c9;
    }
    .valid-alert {
        border-color: red !important;
    }
    .valid-alert::placeholder {
        color: red !important;
    }
    #pg-produc .bottom-btns .back_btn {
        background: #dfdfdf;
        color: #636363;
        position: relative;
        /*left: 10px;*/
    }
    @media only screen and (max-width: 436px) {
        #pg-produc .bottom-btns .nxt_btn {
            background: #96cd55;
            margin-top: 10px;
            position: relative;
            right: 10px;
        }
    }
    .failure h2 {
        color: #ea562f;
    }
    .failure button {
        padding: 15px 25px;
        color: #777777;
        background: #e9e9e9;
        font-weight: 600;
        border: none;
        border-radius: 30px;
    }
    i.fa.fa-close.dainger-ico {
        background: #ea562f;
        color: #fff;
        font-size: 15pt;
        padding: 12px 15px;
        border-radius: 57px;
        box-shadow: 0px 0px 0px 14px #bd535324;
    }
    .cus-details {
        margin-top: 40px;
        padding: 20px;
    }
    table.table.customer {
        border: none;
        margin-top: 10px;
    }
    table.table.customer td {
        width: 50%;
        border-right: 1px #dee2e6;
        padding: 11px 0px;
    }
    .payments {}
    .plan {
        margin-top: 40px;
    }
    
    .pay-succ {
        margin-top: -20px;
    }
    div.a {
        text-transform: uppercase;
    }
    .gaq {
        color: #fff !important;
    }
    .gaq:hover,
    .gaq:visited:hover {
        color: #0393d9 !important;
    }
}

@media only screen and (max-width: 940px) {
    .brand img {
        padding: 8px 5px 10px 8px !important;
        height: 56px !important;
        margin: 7px !important;
    }
}

.brand img {
    padding: 10px;
    height: 75px;
    margin: 0px;
}

input.c_radio {
    opacity: 0;
    position: absolute;
}

a.gaq:hover {
    background: unset;
}

a.gaq {
    line-height: 25px;
}

.qquote-sec {
    margin-top: 25px;
}

.footerminus {
    margin-top: -15px;
}

table.table.customer.table-borderless td {
    padding: 10px;
}

.cus-details {
    margin-top: 15px;
}

h6.text-center.plan {
    margin-top: 30px;
}

#pg-produc .bottom-btns button {
    background: #a2a2a2;
}

@media (max-width: 397px) {
    #pg-produc .bottom-btns .nxt_btn {
        margin: 10px;
    }
}

@media only screen and (max-width: 550px) {
    .in_carousel button {
        padding: 2% 6%;
        top: 50px;
        position: relative;
    }
}

@media only screen and (max-width: 480px) {
    .in_carousel button {
        padding: 2% 6%;
        top: 30px;
        position: relative;
    }
}

@media only screen and (max-width: 340px) {
    .in_carousel button {
        padding: 2% 6%;
        top: 20px;
        position: relative;
    }
}

@media (max-width: 634px) {
    .owl-dots {
        display: none;
    }
}

#cover .owl-stage-outer .owl-stage .owl-item {
    height: 100% !important;
}

#cover .owl-stage-outer .owl-stage {
    height: 100% !important;
}

#cover .owl-stage-outer,
.owl-carousel.owl-theme.owl-loaded.owl-drag {
    width: 100% !important;
    height: 100% !important;
}

#cover .owl-carousel {
    height: 100%;
    overflow: visible;
}

.cover .owl-item.active {
    visibility: visible;
}

.cover .owl-item {
    visibility: hidden;
}

#cover .owl-dots {
    display: none;
}

.cover .cvr-right-inner {
    display: none;
}

.cover .active .cvr-right-inner {
    display: block;
}

@media screen and (max-width:767px) {
    #cover .owl-carousel {
        min-height: 375px;
    }
}

@media screen and (max-width:536px) {
    .cvr-right-inner {
        width: 61%;
        min-height: 435px;
        top: 35px;
        padding: 19px;
    }
}

@media screen and (max-width: 450px) {
    #cover .owl-carousel {
        min-height: 270px;
    }
    .cvr-right-inner {
        width: 60%;
        min-height: 435px;
        top: 100px;
        padding: 1px;
    }
}

@media screen and (max-width:399px) {
    #cover .owl-carousel {
        max-height: 434px;
    }
}

div#preloader {
    background: url(../images/cover-loader.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff !important;
    display:none;
}

.kXDOdx {
    display: none;
}

.cgSxWb ul {
    display: none;
}

.jeLKYt ul {
    display: none;
}


/* .prt-right p img{
position: relative;
right: 10px;
margin-left: 10px;
}
.prt-left p img{
position: relative;
left: 10px;
margin-right: 10px;
} */

@media screen and (max-width:1293px) and (min-width:1240px) {
    .listening_time p {
        display: none;
    }
}

@media screen and (max-width: 1199px) and (min-width: 768px) {
    .prt-left,
    .prt-right {
        padding-top: 20px;
    }
}

a.tellink::after {
    display: none;
}

a.tellink:hover {
    background: unset;
    color: unset;
}

li.call_li {
    padding: 10px 0px;
}

.contactinput {
    border-bottom: 1px solid red !important;
}

@media screen and (max-width:767px) {
    .hide-767 {
        display: none;
    }
    .view-767 {
        display: block;
    }
}

@media screen and (min-width:768px) {
    .view-767 {
        display: none;
    }
    .hide-767 {
        display: block;
    }
}

@media screen and (max-width:450px) {
    .slide {
        background-position: -115px 0px !important;
    }
}

@media screen and (max-width:380px) {
    .cvr-right-inner {
        padding-left: 20px;
    }
}


/* new css */

.slide {
    background-position: center;
}

@media screen and (max-width:1610px) {
    .in_carousel button {
        margin: 62% 43% 27% 43%;
    }
    .in_carousel h3 {
        min-height: 140px;
    }
}

@media screen and (max-width:1465px) {
    .in_carousel button {
        margin: 55% 43% 27% 43%;
    }
    .in_carousel h3 {
        min-height: 140px;
    }
}

@media screen and (max-width:1330px) {
    .in_carousel button {
        margin: 50% 43% 27% 43%;
    }
    .in_carousel h3 {
        min-height: 140px;
    }
}

@media screen and (max-width:1245px) {
    .in_carousel button {
        margin: 48% 43% 27% 43%;
        width: 40px !important;
        height: 40px !important;
        padding: 0px;
    }
}

@media screen and (max-width:1170px) {
    .in_carousel button {
        margin: 44% 43% 27% 43%;
    }
    .in_carousel h3 {
        font-size: 14pt;
    }
}

@media screen and (max-width:1099px) {
    .in_carousel button {
        margin: 40% 43% 27% 43%;
    }
}

@media screen and (max-width:1050px) {
    .in_carousel button {
        margin: 37% 43% 27% 43%;
    }
}

@media screen and (max-width: 991px) {
    .in_carousel button {
        margin: 43% 43% 40% 43%;
    }
}

@media screen and (max-width: 875px) {
    .in_carousel button {
        margin: 38% 43% 40% 43%;
    }
}

@media screen and (max-width: 825px) {
    .in_carousel button {
        margin: 30% 43% 40% 43%;
    }
}

@media screen and (max-width: 800px) {
    .in_carousel button {
        margin: 25% 43% 40% 43%;
    }
}

@media screen and (max-width: 767px) {
    .in_carousel button {
        margin: 50% 43% 40% 43%;
    }
    .featured_in .owl-dots {
        display: none;
    }
}

@media screen and (max-width: 663px) {
    .in_carousel button {
        margin: 40% 43% 40% 43%;
    }
}

@media screen and (max-width: 594px) {
    .in_carousel button {
        margin: 33% 43% 40% 43%;
    }
}

@media screen and (max-width: 380px) {
    .in_carousel button {
        margin: 27% 43% 40% 43%;
    }
}

@media screen and (max-width: 350px) {
    .in_carousel button {
        margin: 20% 43% 40% 43%;
    }
}

@media screen and (max-width: 1200px)and (min-width:767px) {
    .sel-and-prot-in.sel-and-prot-in1 {
        background: #66a8de !important;
        text-align: center;
    }
    .sel-and-prot-in2 {
        background: #e96d38 !important;
        text-align: center;
    }
    .sel-and-prot-in h3,
    .sel-and-prot-in p {
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width:1293px) and (min-width:1251px) {
    .listening_time h4 {
        margin-top: 10px;
    }
}

@media screen and (max-width:875px) and (min-width:799px) {
    nav ul li a,
    nav ul li a:visited {
        padding: 0px 5px;
        font-size: 11pt;
    }
}

@media screen and (max-width:799px) {
    .mid-right {
        padding-right: 100px;
        background-position: 50%;
    }
    .mid-left {
        padding-right: 115px;
    }
    .call_mob {
        position: absolute;
        right: 100px;
        border: unset;
    }
    ul.nav-dropdown {
        width: initial;
    }
    ul.nav-dropdown {
        max-height: 300px;
        overflow: scroll;
    }
    .nav-list a {
        max-width: 300px;
    }
}

@media screen and (max-width:767px) {
    .cvr-right-inner h1 {
        line-height: 22pt;
    }
}

@media screen and (max-width:704px) {
    .top1 {
        background-position: 25%;
    }
    .mid-right {
        background-position: 35%;
    }
}

@media screen and (max-width:638px) {
    .mid-right {
        background-position: 65%;
    }
}

@media screen and (max-width:575px) {
    .mid-left,
    .mid-right {
        padding-right: unset;
        padding: 25px !important;
    }
}

@media screen and (max-width:485px) {
    .cvr-right-inner {
        padding: 15px 0px;
    }
}

@media screen and (max-width:460px) {
    ul.nav-list {
        width: 110%;
    }
    .call_mob h4 {
        font-size: 10pt;
    }
}

@media screen and (max-width:410px) {
    .call_mob {
        right: 70px;
    }
}

@media screen and (max-width:400px) {
    .cvr-right-inner {
        padding-left: 20px !important;
    }
}

@media screen and (max-width:370px) {
    .call_mob {
        display: none !important;
    }
    li.call_li {
        display: block;
    }
}

@media screen and (max-width:360px) {
    .cover .cvr-right-inner {
        right: -32px;
        padding-left: 0px !important;
    }
}

.prd-what-right table td {
    font-size: 12pt;
    font-weight: 400;
}

.gen-your-quote .offer-btn {
    padding: 10px 15px !important;
    color: #8b9bd5 !important;
    background: #eaf2ff !important;
    border: none !important;
    border-radius: 20px !important;
    font-weight: 500 !important;
}

div#sl_address .select:after {
    display: none !important;
}

select#post_address {
    appearance: button;
    color: #495057;
}

.quote-summary-sec center.my-2 {
    display: inline-block;
    margin: 10px;
}

@media screen and (max-width:336px) {
    .payment-btn {
        margin-top: 1rem !important;
    }
    .quote-summary-sec .col-12 {
        text-align: center;
    }
}

.brand,
.call_li.call_mob {
    z-index: 999;
}

.hide-sm.side_bar {
    background: url('../images/bg.png') no-repeat top left/cover;
    height: 100%;
    padding-top: 40px;
    text-align: center;
}

.hide-sm.side_bar img {
    max-width: 330px;
    margin: 5% auto;
    position: relative;
}

.pglftcnt {
    width: fit-content;
    text-align: left;
    margin: 0 auto;
    width: -moz-fit-content;
}

.pglftcnt h2 {
    font-weight: 700;
    color: #fff;
    font-size: 28pt;
}

.pglftcnt h3 {
    color: #fff;
    font-size: 20pt;
}

.pglftcnt h5 {
    font-weight: 600;
    color: #fff;
}

.pglftcnt h2.amt {
    font-weight: 700;
    color: #fff;
    font-size: 28pt;
    display: inline-block;
}

.pglftcnt span {
    color: #fff;
    display: inline-block;
    font-weight: 600;
}

@media screen and (max-width:1199px) {
    .hide-sm.side_bar img {
        max-width: 260px;
    }
}

@media screen and (min-width:1307px) and (max-width:1371px) {
    .smart_watch p {
        padding-right: 16px;
    }
}

a.tellink {
    text-decoration: none;
    color: #000;
}

@media screen and (min-width:991px) and (max-width:1099px) {
    .usl-payment h2,
    .sl-payment h2 {
        font-size: 28pt !important;
    }
}

@media screen and (max-width:1134px) {
    .cvr-right-inner h1 {
        font-size: 25pt;
    }
}

@media screen and (max-width:630px) {
    .cvr-right-inner {
        width: 61%;
        min-height: 435px;
        top: 110px;
        padding: 19px;
    }
    .cvr-right-inner h1 {
        font-size: 20pt;
    }
}

@media screen and (max-width:536px) {
    .cvr-right-inner p {
        display: none;
    }
    .cvr-right-inner h1 {
        font-size: 15pt;
    }
}

@media screen and (max-width:400px) {
    .cvr-right-inner h1 {
        font-size: 13pt;
        line-height: 17pt;
    }
}

@media screen and (max-width: 340px) {
    .cvr-right-inner h1 {
        font-size: 11pt;
        line-height: 17pt;
        margin-top: 48px;
    }
}

@media screen and (min-width:800px) {
    nav ul li ul li {
        min-width: 230px;
    }
}

@media screen and (min-width:1600px) {
    .prt-left,
    .prt-right {
        padding-top: 40%;
    }
}

@media screen and (min-width:1756px) {
    .qquote-sec {
        margin-top: 50px;
    }
    .sel-and-prot-in {
        max-height: 250px;
    }
}

@media screen and (max-width:1034px) {
    .sel-and-prot-in {
        min-height: 200px;
    }
}

@media screen and (max-width:767px) {
    .qquote-sec {
        margin-top: 0px;
    }
}

@media screen and (max-width:550px) {
    section#sel-and-prot-sec {
        padding: 10px 0px;
    }
}

@media screen and (min-width:768px) {
    .featured_in .in_carousel {
        width: 90%;
    }
}

@media screen and (min-width:1500px) {
    .nav-container {
        max-width: 81%;
    }
}

label.usl-payment {
    min-width: 160px;
}


/* productpage list icons extra css */

.prt-right p img {
    position: absolute;
    left: -45px;
    top: -7px;
}

.prd-page .prt-prd .prt-right p {
    padding-bottom: 10px;
    position: relative;
    left: 25px;
    padding-right: 25px;
}

.prt-left p img {
    position: absolute;
    right: -45px;
    top: -7px;
}

.prd-page .prt-prd .prt-left p {
    padding-bottom: 10px;
    position: relative;
    left: -20px;
    padding-left: 25px;
}
@media screen and (min-width:768px) {
    .prd-page .prt-prd h5 {
        margin-bottom: 15px;
    }
}

.srv-validation-message{
    color:red;
    margin-left: 13px;
}
#contact_sec .form-control:focus {
    outline: unset;
    box-shadow: unset;
}
@media screen and (min-width: 1799px) {
  .sel-and-prot-in.sel-and-prot-in1 {
    background-size: cover;
    border-radius: 10px;
}
}

a.tellink {
    padding: 0px !important;
}
div.abt_footer_in p {
    width: 100% !important;
}
.gen-your-quote .title h5 {
    font-weight: 500 !important;
}
.gen-your-quote .cvrclr{
    color: #0393d9;
    text-decoration: underline;
}
.cntctprcheck .container-check {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    position: relative;
    /* padding-left: 30px; */
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12pt;
    margin-right: 10px;
    user-select: none;
}
.cntctprcheck .container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.cntctprcheck .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #cfcfcf;
    border-radius: 50%;

}
.cntctprcheck .container-check:hover input ~ .checkmark {
    background-color: #ccc;
}
.cntctprcheck .container-check input:checked ~ .checkmark {
    background-color: #0393d9;
}
.cntctprcheck .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.cntctprcheck .container-check input:checked ~ .checkmark:after {
    display: block;
}
.cntctprcheck .container-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* / gadgets page css starts here / */

.gadgetspg {
margin-bottom: 25px;
}

.gadgetspg .gadget-item {
cursor: pointer;
}
.bordr {
    border-style: solid;
    border-color: #33b5e5;
}

/* gadgets page css ends here */

@media print {
    .noprint {
        display:none;
    }
 }
 .printbtn{
    border-radius: 20px;
    border: none;
    padding: 12px 20px;
    color: #fff;
    font-weight: 600;
    background: #f8a211;
    text-decoration: none;
 }
 /******* style added by adithya */
 p#post_sc_address {
    margin-left: 13px;
}
/** adithya */
/* tick animation  */
.usl-payment .checkmark{
    display:none;
  }
.c_radio:checked~.usl-payment .checkmark .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #66bd53;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .c_radio:checked~.usl-payment .checkmark {
    display:block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #66bd53;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  }
  
  .c_radio:checked~.usl-payment .checkmark .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #66bd53;
    }
  }
  /* secure logo */
  .secure_logo img {
    max-width: 100%;
 }
 .date-picker-wrap .react-datepicker-wrapper{
     display : block; 
 }
 .price_block {
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
}
.price_block span {
    font-size: 1.1rem;
    font-weight: 400;
}
.bottom_block {
    padding: 20px;
    border-radius: 6px;
    background-image: -moz-linear-gradient( 149deg, rgb(5,105,201) 0%, rgb(13,165,228) 100%);
    background-image: -webkit-linear-gradient( 149deg, rgb(5,105,201) 0%, rgb(13,165,228) 100%);
    background-image: -ms-linear-gradient( 149deg, rgb(5,105,201) 0%, rgb(13,165,228) 100%);
    background-image: linear-gradient( 149deg, rgb(5,105,201) 0%, rgb(13,165,228) 100%);
}
.quote-summary-sec,
.bottom-btns{
    margin-bottom: 25px;
}
@media(min-width:992px) and (max-width:1199px) {
    
}
@media(min-width:768px) and (max-width:991px) {
    
}
@media(max-width:480px) {
    .bottom_block button{
        display: block;
        width: 100%;
    }
    button.add_field.ad_gad {
        margin: -15px 0;
    }
    #pg-produc .bottom-btns .nxt_btn {
        margin: 0;
    }
    .price_block {
        margin-bottom: 15px;
    }
}
button.mt-5.payment-btn.w-30 {
    margin-bottom: 15px;
}
ul.list li {
    color: #333;
    font-size: 16px;
}
.table.customer td{
    font-size: 15px !important;
}
.table.customer tr td:nth-child(1){
    width: 180px;
}
.range_id_purchase {
    position: relative;
}
.range_id_purchase input{
    padding-left: 40px !important;
}
.range_id_purchase:after{
    content: '£';
    display: block;
    font-size: 23px;
    position: absolute;
    top: 10px;
    left: 20px;
    font-weight: 600;
    color: #5f5f5f;
}
/*table  */

.ct_table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    background: #fff;
  }
  
.ct_table td, .ct_table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
.table_wrap{
    margin: 50px 0 20px;
  }
  .form_control{

    width: 100%;
  }
  .form-group .MuiFormLabel-root.Mui-focused,
  .form-group label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled,
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
    color: #0393d9;
    font-weight: 600;
}
.container-check .MuiCheckbox-colorPrimary.Mui-checked {
    color: #0393d9;
}
.form-group .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #0393d9;
}
.form-group .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0393d9;
    border-width: 2px;
}
.form-group .MuiOutlinedInput-root {
    border-radius: 6px;
}
.form-group {
    margin-bottom: 1.5rem;
}
.form-group .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color:#0393d9;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
    border-color: #d6d6d6;
}
header .brand img {
    max-width: 280px;
    height: auto;
    margin: 7px 0;
}
@media(min-width:768px){
    .navigation .nav-container nav ul li.contact_num, .navigation .nav-container nav ul li.contact_num a {
        min-height: inherit;
    }
    .navigation .nav-container nav ul li.contact_num h4 {
        font-size: 20px;
    }

}
.navigation .nav-container nav ul li.contact_num h4 {
    font-size: 22px;
    margin: 15px 0;
    padding-left: 30px;
    font-weight: 600;
    color: #000;
    position: relative;
}
.navigation .nav-container nav ul li.contact_num h4:after{
    content: "";
    display: block;
    background: url('../images/phone-call.png') no-repeat left center;
    width: 25px;
    height: 25px;
    background-size: 25px;
    position: absolute;
    left: 0;
    top: -3px;
}
/* footer sec css starts here */

footer {
    background: #161616;
    padding: 50px 50px 10px;
}

footer ul {
    list-style-type: none;
    padding-left: 0px;
}

footer h4 {
    font-size: 12pt;
    color: #fff;
}

footer ul li a {
    font-size: 10pt;
    color: gray;
}
footer ul li a:hover{
    text-decoration:none;
    color:#d6d6d6;
}
@media screen and (min-width:768px) {
    .container-custom {
        max-width: 90%;
        margin: 0px auto;
    }
}

@media screen and (min-width:991px) {
    .container-custom {
        max-width: 80%;
        margin: 0px auto;
    }
}

footer .social-icon-wrap {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    margin: 5px 6px;
    padding: 12px 9px;
    box-shadow: 0px 2px 15px 1px rgba(62, 62, 62, .2);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.social-icon-wrap.fb-wrap:hover {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #5270a4;
    border-radius: 50%;
    color:#fff !important;
    text-align: center;
    margin: 5px 6px;
    padding: 9px;
    box-shadow: 0px 2px 15px 1px rgba(62, 62, 62, .2);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.social-icon-wrap.tw-wrap:hover{
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #4eb8ec;
    border-radius: 50%;
    color:#fff !important;
    text-align: center;
    margin: 5px 6px;
    padding: 9px;
    box-shadow: 0px 2px 15px 1px rgba(62, 62, 62, .2);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.social-icon-wrap.gp-wrap:hover{
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #d24c2f;
    border-radius: 50%;
    color:#fff !important;
    text-align: center;
    margin: 5px 6px;
    padding: 9px;
    box-shadow: 0px 2px 15px 1px rgba(62, 62, 62, .2);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.social-icon-wrap.ln-wrap:hover{
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #3b86ac;
    border-radius: 50%;
    color:#fff !important;
    text-align: center;
    margin: 5px 6px;
    padding: 9px;
    box-shadow: 0px 2px 15px 1px rgba(62, 62, 62, .2);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
.social-icon-wrap:hover i{
    color:#fff !important;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
footer i.fab.fa-facebook-f.fb-color {
    color: #5270a4;
}

footer i.fab.fa-twitter.tw-color {
    color: #4eb8ec;
}

footer i.fab.fa-google-plus-g.gp-color {
    color: #d24c2f;
}

footer i.fab.fa-linkedin-in.ln-color {
    color: #3b86ac;
}

footer p {
    color: gray;
    font-size: 10pt;
}

footer hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #80808026;
}

footer span a {
    color: gray;
    font-size: 11pt;
    padding: 0px 10px;
}
footer span a:hover{
    text-decoration: none;
    color:#d6d6d6;
}

footer a.dt-br1 {
    border-right: 1px solid #80808026;
}

@media screen and (max-width:575px) {
    footer {
        padding: 50px 20px 10px;
        text-align: center;
    }
}

footer .fa-facebook-f:before, footer .fa-facebook:before{
    content: "\f39e";
}

/* footer sec css ends here */

.details_block {
    display: inline-block;
    padding: 10px 0;
}
.details_block h4 {
    margin: -15px;
    padding: 12px;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #0393d9;
}
.sub_block h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 2px;
    color: #000;
}
.sub_block {
    margin-bottom: 15px;
}
.sub_block span {
    font-size: 14px;
    color: #333;
}
.notes h6 {
    color: #0393d9;
    font-weight: 600;
}
form#cmg_payment_form h4,
.dd-form.d-block h4 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #0393d9;
}
button.mt-5.payment-btn.w-30 {
    background-image: linear-gradient( 135deg, #5aad31 0%, #a5de61 100%);
    color: #fff;
    border-radius: 5px;
}
table.ct_table th {
    background: #0393d9;
    color: #fff;
}
table.ct_table.table_details {
    width: 900px;
}
.table_wrap .ct_table {
    border-top: 3px solid #0393d9;
}
.sucess-sec h2 {
    color: #66bd53;
    font-size: 20px;
    margin: 20px 0;
}
.close_ico{
    width: 30px;
    height: 30px;
    background: url('../images/close.png') no-repeat center center/contain;
    background-size: 30px;
    border: 0;
    position: absolute;
    top: 5px;
    right: 15px;
    
}